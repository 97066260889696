.gallery-rec1 {
    display: flex;
    width: 29px;
    height: 104px;
    border-radius: 0px 10px 10px 0px; 
    background: #FF0000;
  }
  
  .gallery-box1 {
    display: flex;
    margin-bottom: 3%;
  }
  

  .loading-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #f9f9f9;
  }
  
  .loading-spinner {
    width: 50px;
    height: 50px;
    border: 5px solid #ddd;
    border-top: 5px solid #007bff;
    border-radius: 50%;
    animation: spin 1s linear infinite;
  }
  
  @keyframes spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }