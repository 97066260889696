/* Failure Container */
.failure-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  margin-left: 30px;
  margin-right: 30px;
  background-color: #fef6f6; /* Light red tint */
}

/* Failure Message */
.failure-message {
  text-align: center;
  background: #ffffff;
  padding: 40px 20px;
  border-radius: 10px;
  box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.1);
  animation: fadeIn 0.8s ease-in-out;
}

/* Failure Icon */
.failure-icon {
  font-size: 50px;
  color: #f44336; /* Red for failure */
  margin-bottom: 20px;
  animation: shake 0.8s infinite;
}

/* Failure Title */
.failure-title {
  font-size: 28px;
  font-weight: bold;
  color: #333333;
  margin-bottom: 10%;
}

/* Failure Text */
.failure-text {
  font-size: 16px;
  color: #666666;
  margin-bottom: 10px;
}

/* Failure Redirect */
.failure-redirect {
  font-size: 14px;
  color: #999999;
}

/* Animations */
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: scale(0.8);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}

@keyframes shake {
  0%, 100% {
    transform: translateX(0);
  }
  25% {
    transform: translateX(-5px);
  }
  75% {
    transform: translateX(5px);
  }
}
