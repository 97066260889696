body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* Utilities css */

@import './utilities/UpArrow.css';
/* Landing Page */

@import './stylesheets/Home.css';
@import './stylesheets/About.css';
@import './stylesheets/App.css';
@import './stylesheets/Contact.css';
@import './stylesheets/Footer.css';
@import './stylesheets/Gallery.css';
@import './stylesheets/Header.css';
@import './stylesheets/News.css';
@import './stylesheets/success.css';
@import './stylesheets/failure.css';

/* branch1  */
@import './branches/branch1/stylesheets/Admissions.css';
@import './branches/branch1/stylesheets/Alumina.css';
@import './branches/branch1/stylesheets/App.css';
@import './branches/branch1/stylesheets/Application.css';
@import './branches/branch1/stylesheets/Facilities.css';
@import './branches/branch1/stylesheets/Faculties.css';
@import './branches/branch1/stylesheets/Footer.css';
@import './branches/branch1/stylesheets/Gallery.css';
@import './branches/branch1/stylesheets/Header.css';
@import './branches/branch1/stylesheets/Home.css';
@import './branches/branch1/stylesheets/Login.css';
@import './branches/branch1/stylesheets/News.css';
@import './branches/branch1/stylesheets/Register.css';
@import './branches/branch1/stylesheets/generate.css';