.application {
    font-family: Arial, sans-serif;
    padding: 20px;
    background-color: #f9f9f9;
}

.registration-form {
    max-width: 800px;
    margin: 0 auto;
    background-color: #fff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.formHeading h1 {
    text-align: center;
    color: #FF0000;
    margin-bottom: 20px;
    font-weight: bold;
}

.container-application-no {
    width: auto;
    display: flex;
    align-items: center;
}


.application-name {
    color: #00008B;
}

.application-no {
    margin-top: 10px;
    max-width: 40vw;
    width: auto;
    color: #00008B;
    border: 0px;
    border-bottom: 2px dotted #00008B; 
    padding-bottom: 5px; 
    display: inline-block;
    font-size: 20px;
    font-weight: bold;
}

h2 {
    color: #FF0000;
    margin-top: 24px;
    text-align: center;
    background-color: #00008B;
    padding: 10px;
    border-radius: 5px;
}


input,
select,
.application-button {
    width: 100%;
    padding: 8px;
    border: 1px solid #FF0000;
    border-radius: 4px;
    box-sizing: border-box;
    margin-bottom: 15px;
    font-size: 16px;
}

input[type="date"] {
    padding: 6px;
}

.dateInput {
    height: 38px;
    color: #00008B;
    width: 100%;
}
select{
    color: #00008B;
}
.application-button {
    cursor: pointer;
    background-color: #FF0000;
    color: white;
    border: none;
    margin-top: 10px;
    border-radius: 4px;
}


.same-as-residential-label{
    color: #00008B;
    font-weight: bold;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.same-as-residential-label-input{
    gap: 0px;
}


.form-group span {
    color: red;
    font-size: 12px;
}

.addressSection,
.photoLabels {
    margin-top: 20px;
}

.addressSection input {
    width: 100%;
}

table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 10px;
}

th, td {
    text-align: center;
    padding: 10px;
    /* border: 1px solid #FF0000; */
}

th {
    color: #00008B;
}

input[type="number"]:read-only {
    background-color: #f0f0f0;
}

.btn-container{
    display: flex;
    flex-direction: row;

}
.btn{
   width: 52px;
   border-radius: 60%;
   display: flex;
   justify-content: center;
   align-items: center;
   margin: 5px;
   background-color: #00008B;
   color:white;
}
.btn1{
    background-color: #FF0000;
}

.registration-form {
    padding: 15px;
}

.form-actions {
    display: flex;
    flex-direction: row;
   justify-content: space-around;
}

.form-actions button {
    max-width: 20%;
    /* min-width: fit-content; */
    margin-bottom: 10px;
    background-color: #00008B;
    color: #FF0000;
    font-weight: bold;
}

.textarea-field{
    width: 100%;
    height: 70px;
    padding: 8px;
    border: 1px solid #FF0000;
    border-radius: 4px;
    box-sizing: border-box;
    margin-bottom: 15px;
    font-size: 16px;
    resize: none;
}

.textarea-field:focus {
    border-color: #FF0000;
    outline: none;
    box-shadow: 0 0 2px #FF0000;
}

/* number input field default changes */
input[type="number"] {
    -moz-appearance: textfield;  /* Firefox */
    -webkit-appearance: none;   /* Chrome, Safari, Edge */
    appearance: none;           /* Standard */
  }

input[type="number"]::-webkit-inner-spin-button, 
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}


.photo-container {
    width: 100%;
}

.form-group-photo {
    padding: 15px;
}



@media (min-width: 768px){
    .container-split-desktops{
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 10px;
    }
    .photo-container {
        width: 100%;
        display: grid;
        grid-template-columns: repeat(2, 1fr); /* Two columns */
        gap: 20px; /* Space between items */
    }
    
    .form-group-photo {
        padding: 15px;
        box-sizing: border-box;
    }
    
}


.same-address-css {
    text-align: center;
    display: flex;
    margin-bottom: 20px;
  }

  .checkbox-css{
    margin: 0;
    width: 20px;  /* Adjust width */
    height: 20px; /* Adjust height */
    accent-color: #007bff; /* Change checkbox color */
    cursor: pointer;
  }


  .span-check-box{
    width: 45vw;
    color: #00008B;
    font-weight: bold;
  }