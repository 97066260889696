/* Login.css */

/* Base styles */
.login-container {
  min-height: 100vh;
  background-color: #f9fafb;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 2rem 1rem;
}

.login-header {
  text-align: center;
  margin-bottom: 2rem;
}

.login-icon {
  height: 48px;
  width: 48px;
  color: #2563eb;
  margin: 0 auto;
}

.login-title {
  margin-top: 1rem;
  font-size: 1.875rem;
  font-weight: 700;
  color: #1f2937;
}

.login-form-container {
  background-color: #ffffff;
  padding: 2rem;
  border-radius: 0.375rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 24rem;
}

.login-form {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

.form-group {
  display: flex;
  flex-direction: column;
}

.form-label {
  font-size: 0.875rem;
  color: #4b5563;
  margin-bottom: 0.5rem;
}

.form-input {
  padding: 0.75rem;
  font-size: 1rem;
  border: 1px solid #d1d5db;
  border-radius: 0.375rem;
  outline: none;
  transition: border-color 0.2s ease;
}

.form-input:focus {
  border-color: #2563eb;
  box-shadow: 0 0 0 1px #2563eb;
}

.submit-button {
  padding: 0.75rem;
  background-color: #2563eb;
  color: #ffffff;
  font-size: 1rem;
  font-weight: 500;
  text-align: center;
  border: none;
  border-radius: 0.375rem;
  cursor: pointer;
  transition: background-color 0.2s ease;
}

.submit-button:hover {
  background-color: #1d4ed8; 
}

.toggle-button {
  margin-top: 1rem;
  font-size: 0.875rem;
  color: #2563eb;
  text-align: center;
  cursor: pointer;
  transition: color 0.2s ease;
}

.toggle-button:hover {
  color: #1d4ed8;
}

/* Responsive styles */
@media (max-width: 768px) {
  .login-container {
    padding: 1.5rem 1rem;
  }

  .login-title {
    font-size: 1.5rem;
  }

  .login-form-container {
    padding: 1.5rem;
  }

  .form-label {
    font-size: 0.875rem;
  }

  .form-input {
    font-size: 0.875rem;
    padding: 0.5rem;
  }

  .submit-button {
    font-size: 0.875rem;
    padding: 0.625rem;
  }

  .toggle-button {
    font-size: 0.75rem;
  }
}

@media (max-width: 480px) {
  .login-title {
    font-size: 1.25rem;
  }

  .login-icon {
    height: 36px;
    width: 36px;
  }

  .login-form-container {
    padding: 1rem;
  }

  .form-label {
    font-size: 0.75rem;
  }

  .form-input {
    font-size: 0.75rem;
    padding: 0.5rem;
  }

  .submit-button {
    font-size: 0.75rem;
    padding: 0.5rem;
  }

  .toggle-button {
    font-size: 0.75rem;
  }
}
