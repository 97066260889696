.b1-footer {
    bottom: 0;
    background-color: #00008BA8;
    color: white;
    text-align: left; 
    border-top: 3px solid white;
    /* bottom: 0px; */
    margin-top: 10%;    
    /* position: sticky; */
}

.b1-footer-content {
    display: flex;
    justify-content: space-between;
    margin: 0 5%;
    align-items: flex-start;
    /* bottom: 0px; */
    /* position: sticky; */
}

.b1-footer-left {
    max-width: 50%;  
}

.b1-footer-right {
    text-align: right;
}

.b1-school-name {
    font-family: inter;
    font-size: 24px;
    font-weight: 800;
    margin-bottom: 10px;
}
.b1-ft-title{
    font-family: inter;
    font-size: 24px;
    font-weight: 800;
    margin-bottom: 10px;
    margin-top: 20px;
}
.b1-caption {
    font-size: 16px;
}



@media (max-width: 768px) {
    .b1-footer-content {
        flex-direction: column;
        text-align: center;
    }

    .b1-footer-left, .b1-footer-right {
        max-width: 100%;
    }

    .b1-footer-right {
        text-align: center; 
    }

    .b1-school-name {
        font-size: 20px;
        font-weight: 600;
        margin-bottom: 8px;
    }
    .b1-ft-title{
        font-size: 20px;
        font-weight: 600;
        margin-bottom: 8px;
        margin-top: 18px;
    }
    .b1-caption {
        font-size: 14px;
    }
}
@media (max-width: 480px){
    .b1-school-name {
        font-size: 18px;
        font-weight: 400;
        margin-bottom: 6px;
    }
    .b1-ft-title{
        font-size: 18px;
        font-weight: 400;
        margin-bottom: 6px;
        margin-top: 16px;
    }
    .b1-caption {
        font-size: 12px;
    }
}