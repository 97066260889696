/* Spinner Styles */
.spinner {
    border: 4px solid rgba(0, 0, 0, 0.1);
    border-left-color: #4caf50; /* Customize the spinner color */
    border-radius: 50%;
    width: 40px;
    height: 40px;
    animation: spin 1s linear infinite;
  }
  
  @keyframes spin {
    to {
      transform: rotate(360deg);
    }
  }
  
  /* Center the spinner */
  .loading-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
  }
  