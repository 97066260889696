.home-arrow {
    position: fixed;
    bottom: 20px; 
    right: 20px; 
    padding: 20px;
    background-color: #00008BA8; 
    color: white;
    border-radius: 60%;
    cursor: pointer;
    font-size: 14px; 
    z-index: 1000; 
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    transition: background-color 0.3s ease;
  }
  
  .home-arrow:hover {
    background-color: #00008B;
  }
  