/* Container styles */
.container-generate {
  width: 90%;
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  animation: fadeIn 0.5s ease-out;
  margin-top: 10%;
  margin-bottom:10% ;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  justify-items:center ;
}


/* Input and button styles */
input[type="text-generate"], 
input[type="number"],
button-generate {
  width: 100%;
  /* justify-items: center;
  align-content: center; */
  padding: 1%;
  margin: 12px 0;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
  transition: all 0.3s ease;
}

/* Focus styles */
input[type="text-generate"]:focus,
input[type="number"]:focus {
  border-color: #4CAF50;
  outline: none;
}

/* Button styles */
.payment-button {
  background-color: #4CAF50;
  color: white;
  border: none;
  cursor: pointer;
  font-weight: bold;
  padding: 2%;
}

.payment-button:hover {
  background-color: #45a049;
  transform: scale(1.05);
}

.payment-button:active {
  transform: scale(1);
}

/* Student details container */
.student-details-generate {
  margin-top: 20px;
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  opacity: 0;
  animation: fadeInDetails 1s forwards;
}

/* Detail row layout */
.detail-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0;
  border-bottom: 1px solid #eee;
  transition: background-color 0.3s ease;
}

.detail-row:hover {
  background-color: #f1f1f1;
}

.detail-row:last-child {
  border-bottom: none;
}

.label-generate {
  font-weight: bold; 
  color: #333;
  /* width: 45%;  */
}

.value-generate {
  color: #555;
  text-align: right;
  width: 50%; /* Fixed width for values */
  word-wrap: break-word; /* Ensure long values wrap properly */
}

/* Responsive design */
@media (max-width: 768px) {
  .container-generate{
    width: 95%;
  }

  input[type="text-generate"],
  input[type="number"],
  button {
    font-size: 14px;
  }
}

@media (max-width: 480px) {
  
  .container-generate {
    width: 100%;
    padding: 10px;
    margin-top: 20%;
  }


  input[type="text-generate"],
  input[type="number"],
  button {
    font-size: 12px;
  }

  .label-generate,
  .value-generate {
    width: 100%; /* Stack labels and values for small screens */
    text-align: left;
    margin-bottom: 5px;
  }

  .detail-row {
    flex-direction: column; /* Stack label and value vertically on small screens */
    align-items: flex-start;
  }
}

/* Animations */
@keyframes fadeIn {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fadeInDetails {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
