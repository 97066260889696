.home1-container {
  display: flex;
  flex-direction: column;
  margin-left: 50px;
  margin-right: 50px;
}

.home-school-img {
  position: relative;
  width: 100%;
  overflow: hidden;
}

.home-school-img img {
  width: 100%;
  height: auto;
  display: block;
}

.textHeading {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
  text-align: center;
  padding: 20px;
  box-sizing: border-box;
}

.textHeading h1 {
  margin: 10px 0;
  font-size: 1.2rem;
  line-height: 1.4;
}

.btn_contact {
  display: inline-block;
  margin-top: 15px;
  padding: 10px 20px;
  font-size: 1rem;
  color: white;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  background: rgb(0, 0, 139);
  background: linear-gradient(90deg,
      rgba(0, 0, 139, 1) 50%,
      rgba(3, 3, 3, 1) 100%);
}





/* Welcome to Sandeepani Group of Institutions */

.welcome_div {
  height: fit-content;
  width: 100%;
  margin-bottom: 25px;
  background: linear-gradient(90deg,
      rgba(0, 0, 139, 1) 50%,
      rgba(3, 3, 3, 1) 100%);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-bottom: 5%;
}

.welcome_div p {
  color: #ff0000;
  width: 90%;
  text-align: center;
  font-size: 50px;
  font-family: "Port Ligat Sans", sans-serif;
  font-weight: bold;
  margin-bottom: 10px;
}

.welcome_div p:last-child {
  color: #ff0000;
  width: 100%;
  text-align: center;
  font-size: larger;
  font-size: 20px;
}


/* about sandipanni */

.abt-school {
  width: 100%;
}

/* best school in banglore */
.best-school {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  background: rgb(0, 0, 139);
  background: linear-gradient(90deg,
      rgba(0, 0, 139, 1) 50%,
      rgba(3, 3, 3, 1) 100%);
  padding-bottom: 5%;
}

.br1-img>img {
  position: absolute;
  opacity: 0.1;
  width: 100%;

}

.flex-container {
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  /* position: absolute; */
  top: auto;
  z-index: 1;
  width: 100%;
}

.iframe-container,
.description-container {
  flex: 1;
  margin: 0 10px;
  box-sizing: border-box;
  z-index: 100;
}

.iframe-container iframe {
  width: 100%;
}

.description-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
}

.description-container>p {
  color: #fff;
  margin-top: 0;
  width: 100%;
}

.description-container h3 {
  font-size: 40px;
  font-weight: bold;
}

/* quick-link*/

.quickLinkContainer {

  background: rgb(0, 0, 139);
  background: linear-gradient(90deg,
      rgba(0, 0, 139, 1) 50%,
      rgba(3, 3, 3, 1) 100%);
  text-align: center;
}

.quickLinkContainer h1 {
  color: red;
  font-size: 36px;
  margin-bottom: 30px;
}

.gridContainer {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 20px;
  width: 100%;
  padding: 20px;
  box-sizing: border-box;
}

.gridItem {
  text-align: center;
  /* Center align content */
  padding: 10px;
  background: linear-gradient(360deg, #0a0d7d, #2c2e91);
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
}

.gridItem:hover {
  transform: scale(1.05);
}

.gridItem img {
  max-width: 100px;
  height: auto;
  margin-bottom: 10px;
}

.gridItem h2 {
  font-size: 18px;
  color: #fff;
  margin: 0;
}

/* Cards */

.cards-container {
  display: flex;
  width: 100%;
  gap: 20px;
  justify-content: space-evenly;
}

.card {
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin: 0 10px;
  text-align: left;
  border: 1px solid #ddd;
  font-size: 30px;
  font-weight: bold;
}

.card-header {
  background: linear-gradient(360deg, #0a0d7d, #2c2e91);
  color: #fff;
  font-size: 18px;
  padding: 20px;
  border-radius: 5px 5px 0 0;
  margin-bottom: 15px;
  text-align: center;
}

.card ul {
  list-style-type: none;
  line-height: 1.7rem;
}

.card ul li {
  font-size: 16px;
  margin-bottom: 10px;
  position: relative;
  padding-left: 30px;
}

.card ul li::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 20px;
  height: 20px;
  background-image: url("../../../resources/images/dot.svg");
  background-size: contain;
  line-height: 1.5rem;
  background-repeat: no-repeat;
}

/* Principal Desk */

.principal-container {
  display: flex;
  flex-direction: column;
}
.gridDesk {
  display: flex;
}

/* Top performances */

.flexTop {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 20px;
  padding: 20px;
  box-sizing: border-box;
  width: 100%;
}
.top1 {
  text-align: center;
  background: linear-gradient(360deg, #3237d3, #2c2e91);
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  padding: 15px;
  transition: transform 0.3s ease;
}
.top1:hover {
  transform: scale(1.05);
}
.top1 img {
  max-width: 100%;
  height: auto;
  border-radius: 10px;
}
.h3Top {
  color: #ffffff;
  font-weight: bold;
  margin-top: 10px;
  font-size: 1.2rem;
  word-wrap: break-word;
}

/* Conclusion */

.containerConclu {
  display: flex;
  margin-top: 20px;
  background-image: url("../../../resources/images/image_1.png");
  background-size: cover;
  background-position: center;
  justify-content: center;
  align-items: center;
  object-fit: cover;
  filter: brightness(0.5px);
  padding: 20px;
  color: white;
}
.conclusion-content{
  font-size: 24px;
  font-weight: 400;
}

.gridConclu {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  gap: 0;
  position: relative;
  align-items: center;
}

.gridConclu::before,
.gridConclu::after {
  content: "";
  position: absolute;
  background-color: white;
}

.gridConclu::before {
  top: 50%;
  left: 0;
  right: 0;
  height: 2px;
}

.gridConclu::after {
  left: 50%;
  top: 0;
  bottom: 0;
  width: 2px;
}

.grid-itemConclu {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
  font-size: 24px;
  border: none;
}

.info {
  font-size: 2em;
  color: red;
  font-weight: bold;
}



/* Mobile-first responsive design */
@media (max-width: 768px) {
  .textHeading {
    padding: 15px;
  }

  .textHeading h1 {
    font-size: 1rem;
    margin: 8px 0;
  }

  .btn_contact {
    padding: 8px 16px;
    font-size: 0.8rem;
  }

  .welcome_div {
    height: 150px;
  }

  .welcome_div p {
    font-size: 35px;
  }

  .abt-school h4 {
    font-size: 20px;
    /* Smaller heading for tablets */
  }

  .abt-school p {
    font-size: 16px;
    /* Adjust paragraph font size for tablets */
    word-spacing: 0.4rem;
    /* Slightly reduce word spacing */
    padding: 0 15px;
    /* Add padding for smaller devices */
  }

  .grid-container {
    grid-template-columns: repeat(2, 1fr);
    /* 2 Columns */
  }

  .flexTop {
    grid-template-columns: repeat(2, 1fr);
    /* Two items per row */
    gap: 15px;
  }

  .h3Top {
    font-size: 1rem;
    /* Adjust font size */
  }

}

@media (max-width: 480px) {
  .home1-container {
    margin-left: 20px;
    margin-right: 20px;
  }

  .textHeading {
    padding: 10px;
  }

  .textHeading h1 {
    font-size: 0.9rem;
  }

  .btn_contact {
    padding: 6px 12px;
    font-size: 0.75rem;
  }

  .welcome_div {
    height: 120px;
  }

  .welcome_div p {
    font-size: 20px;
  }

  .welcome_div p:last-child {
    font-size: 14px;
  }

  .abt-school h4 {
    font-size: 18px;
    /* Smaller heading for mobile */
  }

  .abt-school p {
    font-size: 14px;
    /* Smaller paragraph font size for mobile */
    word-spacing: 0.3rem;
    /* Further reduce word spacing */
    line-height: 1.4;
    /* Adjust line height for small screens */
  }

  .flex-container {
    flex-direction: column;
  }

  .cards-container {

    flex-direction: column;
  }

  .grid-container {
    grid-template-columns: 1fr;
    /* 1 Column */
  }

  .principal-container {
    flex-direction: column;
  }

  .gridDesk {
    flex-direction: column;
  }

  .flexTop {
    grid-template-columns: 1fr;
    /* Single column layout */
    gap: 10px;
  }

  .h3Top {
    font-size: 0.9rem;
    /* Smaller font size for mobile */
  }

  .top1 img {
    max-width: 100%;
    /* Images scale to fit the container */
    height: auto;
  }
  .containerConclu {
    flex-direction: column;
  }
  .conclusion-content{
    font-size: medium;
  }
  .info {
    font-size: 30px;
  }
}