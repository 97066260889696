.contact-container {
    display: flex;
    flex-direction: column;
    margin: 0px 50px 50px 50px;
    background-color: rgb(255, 255, 255);

}


@import url('https://fonts.googleapis.com/css2?family=Port+Lligat+Sans&display=swap');

body {
    font-family: 'Arial', sans-serif;
    margin: 0;
    padding: 0;
    background-color: #f5f5f5;

}

.contact1-container {
    display: flex;
    justify-content: space-between;
    border: 2px solid red;
}

/* Contact Form */
.contact-form {
    display: flex;
    justify-content: center;
    padding: 40px;
}

.contact-form h2 {
    color: #E74C3C;
    /* Red */
    font-size: 28px;
    margin-bottom: 20px;
    font-family: 'Times New Roman', Times, serif;
}

.contact-form p {
    color: #00008B;

    font-size: 20px;
    margin-bottom: 30px;
    font-family: 'Times New Roman', Times, serif;
}

input:focus {
    outline: none;
}

.contact-form input,
.contact-form textarea {
    width: 80%;
    padding: 10px;
    margin-top: 20px;
    border: none;
    color: #00008B;
    font-size: 16px;

}

::placeholder {
    color: #00008B;
}





.contact-form button:hover {
    background-color: white;
    color: #E74C3C;
    border: 2px solid #E74C3C;
}

/* Contact Info */
.contact-info {
    padding: 60px;
    background-color: #1E2C74;
    color: rgb(255, 255, 255);
    position: absolute;
    text-align: center; 
    width: 30%;
    right: 10%;
    top: 45%;
}

.submit {
    display: flex;
    justify-content: center;
    width: 82%;
}

.submit input {
    background-color: #ffffff;
    border: 2px solid #FF0000B2;
    cursor: pointer;
    width: 138px;
    font-size: 16px;
    font-weight: 400px;

    border-radius: 10px;

}

.contact-info h3 {

    font-size: 35px;
    font-family: "Port Lligat Sans", sans-serif;
    font-weight: 400;
    font-style: normal;
}

.contact-info p {
    font-size: 18px;
    margin-bottom: 10px;
}

.contact-info a {
    color: white;
    text-decoration: none;
    font-size: 16px;
}

.contact-info a:hover {
    text-decoration: underline;
}


.phoneCont {
    display: flex;
    gap: 20px;
}

.phoneCont p {
    color: #f5f5f5;
    font-size: 22px;
    font-weight: 550;

}

.mailCont {
    display: flex;
    gap: 20px;
}

.mailCont p {
    color: #f5f5f5;
    font-size: 22px;
    font-weight: 550;

}

/* Red Box */
.red-box {
    top: -20px;
    left: -20px;
    position: absolute;
    width: 50px;
    height: 50px;
    background-color: #ff0000;
}

.red-big-box {
    background-color: #ff0000;
    width: 20vw;
}
@media (max-width: 768px) {
    .contact-container {
        margin: 0px 30px 30% 30px;

    }

    .contact-form {
        padding: 15px;    
        width:50%;
        margin-top: 100%;   
        padding-left: 30%;
        height: auto;
    }
    .contact-form h2 {
        font-size: 18px;
        font-family: 'Times New Roman', Times, serif;
    }
    
    .contact-form p {
        font-size: 14px;
        margin-bottom: 30px;
        
    }
    
    .submit {
        display: flex;
        justify-content: center;
        width: 100%;
    }
    
    .contact-info {
        padding: 120px;
        padding-left: 10%;
        width: 30%;
        right: 2%;
        top:  auto;  
        margin-right: 5%;
    }
    .contact-info h3 {
        font-size: 18px;
        width: 130%;
    }
    
    .phoneCont {
        display: flex;
        margin-top: 25%;
        flex-wrap: wrap;
    }
    
    .phoneCont p {
        font-size: 14px;
        font-weight: 400;
        margin-bottom: 25%;
    }
    
    .mailCont {
        width: 10%;
        flex-wrap:wrap; 
    }
    
    .mailCont p {
        font-size: 14px;
        font-weight: 400;
        
    }
    .red-big-box {     
        width: 40%;
    }
    
}

@media (max-width: 480px) {
    .contact-container {
        margin: 0px 10px 10% 10px;

    }

    .contact-form {
        padding: 10px;
        width: 50%;
        height: auto;
        margin-top: 200%;
    }
    .contact-form h2 {
        font-size: 18px;
        font-family: 'Times New Roman', Times, serif;
    }
    
    .contact-form p {
        font-size: 14px;
        margin-bottom: 30px;
        
    }
    
    .submit {
        display: flex;
        justify-content: center;
        width: 100%;
    }
    
    .contact-info {
        padding: 120px;
        padding-left: 10%;
        width: 40%;
        right: 2%;
        top:  auto;  
        margin-right: 5%;
    }
    .contact-info h3 {
        font-size: 18px;
        width: 130%;
    }
    
    .phoneCont {
        display: flex;
        margin-top: 25%;
        flex-wrap: wrap;
    }
    
    .phoneCont p {
        font-size: 14px;
        font-weight: 400;
        margin-bottom: 25%;
    }
    
    .mailCont {
        width: 10%;
        flex-wrap:wrap; 
    }
    
    .mailCont p {
        font-size: 14px;
        font-weight: 400;
        
    }
    .red-big-box {     
        width: 40%;
    }
    
}