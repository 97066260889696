.footer {
    background-color: #00008BA8;
    color: white;
    text-align: left; 
    border-top: 3px solid white;
    margin-top: auto;
}

.footer-content {
    display: flex;
    justify-content: space-between;
    margin: 0 5%;
    align-items: flex-start;
}

.footer-left {
    max-width: 50%; 
}

.footer-right {
    text-align: right;
}

.school-name {
    font-family: inter;
    font-size: 24px;
    font-weight: 800;
    margin-bottom: 10px;
}
.ft-title{
    font-family: inter;
    font-size: 24px;
    font-weight: 800;
    margin-bottom: 10px;
    margin-top: 20px;
}
.caption {
    font-size: 16px;
}



@media (max-width: 768px) {
    .footer-content {
        flex-direction: column;
        text-align: center;
    }

    .footer-left, .footer-right {
        max-width: 100%;
    }

    .footer-right {
        text-align: center; 
    }
}
