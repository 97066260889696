.register-container {
    width: 300px;
    margin: auto;
    height: auto;
    padding: 20px;
    border: 1px solid #00008B;
    border-radius: 8px;
    background-color: #f5f5f5;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  
  .register-title {
    color: #00008B;
    text-align: center;
  }
  
  .register-input {
    width: 90%;
    padding: 10px;
    margin: 10px 0;
    border: 1px solid #00008B;
    border-radius: 4px;
  }
  
  .register-button {
    width: 100%;
    padding: 10px;
    background-color: #00008B;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .input-container {
    margin-bottom: 15px; /* Space between input fields */
  }
  
  .error {
    color: red; /* Error text color */
    font-size: 12px; /* Adjust font size for error messages */
  }
  
  