.gallery-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  background-color: #f5f5f5; 
}

.grid-container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr)); 
  gap: 15px;
  width: 100%; 
  max-width: 1200px; 
}

.grid-item {
  width: 100%;
  /* height: auto; */
  height: 150px;;
  border-radius: 10px; 
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2); 
  transition: transform 0.3s, box-shadow 0.3s; 
  cursor: pointer; 
}

.grid-item:hover {
  transform: scale(1.05); 
  box-shadow: 0 8px 30px rgba(0, 0, 0, 0.4); 
}

/* Modal styles */
.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8); 
  display: flex;
  justify-content: center;
  align-items: center;
  transition: opacity 0.5s ease;
}

.modal-image {
  max-width: 90%;
  max-height: 90%;
  border-radius: 10px; 
  box-shadow: 0 8px 30px rgba(0, 0, 0, 0.4);
}

@media (max-width: 768px) {
  .grid-item {
    margin: 0; 
  }
}
