.video-grid-container {
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .video-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 20px;
    width: 100%;
  }
  
  .video-card {
    background: #f9f9f9;
    padding: 10px;
    border-radius: 8px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  }
  
  .video-title {
    font-family: "Port Lligat Sans", sans-serif; 
    font-size: 32px;
    font-weight: 400;
    text-align: center;
    margin-bottom: 2%;
    color:  #00008b;;
    /* margin: 10px 0 0 0;
    font-size: 16px;
    font-weight: bold;
    text-align: center;  */
  }
  
  .subscribe-section {
    margin-top: 20px;
  }
  
  .subscribe-button {
    background-color: #ff0000;
    color: #fff;
    border: none;
    border-radius: 5px;
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer;
    
  }
  
  .subscribe-button:hover {
    background-color: #cc0000;
  }
  
  @media (max-width: 480px){
    .video-title {
        font-family: "Port Lligat Sans", sans-serif; 
        font-size: 26px;
        font-weight: 400;
        text-align: center;
        margin-bottom: 2%;
      }
  }