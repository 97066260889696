/* Default Styles (applies to large screens) */
.header1 {
    display: flex;
    flex-direction: column;
    padding: 10px;
    background-color: #ffffff;
  }
  
  .logo-title {
    display: flex;
    justify-content: left;
    gap: 2%;
  }
  
  .school-name {
    font-family: 'Port Lligat Sans', sans-serif;
    font-size: 48px;
    font-weight: 400;
    color: #00008B;
    padding-top: 3%;
    box-shadow: 0 1px rgba(0, 0, 0, 0.3);
  }
  
  .nav-buttons {
    display: flex;
    align-items: center;
    gap: 20px;
    justify-content: center;
    background-color: #00008b;
    border-radius: 5px;
    padding: 20px;
    margin-bottom: 2%;
  }
  
  .button {
    background-color: transparent;
    color: white;
    border: none;
    font-size: 16px;
    cursor: pointer;
    position: relative;
    transition: color 0.3s ease;
  }
  
  .button::after {
    height: 2px;
    width: 0;
    background-color: red;
    position: absolute;
    bottom: 0;
    left: 0;
    transition: width 0.3s ease;
  }
  
  .button:hover::after {
    width: 100%;
  }
  
  .button:hover {
    color: #ff6347;
  }
  
  .nav-container {
    position: relative;
  }
  
  /* Sidebar Styles */
  .sidebar {
    display: none;
    position: fixed;
    left: -250px;
    top: 0;
    height: 100%;
    background-color: #00008b;
    flex-direction: column;
    gap: 20px;
    padding: 20px;
    transition: left 0.3s ease;
    z-index: 100;
  }
  
  .sidebar.open {
    left: 0;
  }
  
  .hamburger-icon {
    display: none;
    font-size: 30px;
    cursor: pointer;
    padding: 10px;
    margin-top: 10%;
  }
  
  .nav-buttons.hidden {
    display: none;
  }
  
  /* Medium Screens (under 768px) */
  @media (max-width: 768px) {
    .sidebar{
        display: flex;
    }
    .school-name {
      font-size: 32px;
    }
  
    .nav-buttons {
      flex-direction: column;
      gap: 10px;
    }
  
    .button {
      width: 100%;
      text-align: center;
    }
  
    .hamburger-icon {
      display: block;
      position: absolute;
      right: 20px;
      top: 20px;
      margin-top: 8%;
    }
  
    .nav-buttons {
      display: none;
    }
  }
  
  /* Small Screens (under 480px) */
  @media (max-width: 480px) {
    .sidebar{
        display: flex;
    }
    .school-name {
      font-size: 24px;
    }
  
    .nav-buttons {
      padding: 15px;
      gap: 5px;
    }
  
    .button {
      font-size: 14px;
      padding: 10px;
    }
  }
  