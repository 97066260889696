.about-container {
  display: flex;
  flex-direction: column;
  margin-left: 50px;
  margin-right: 50px;
} 
 
.abt-title1 {
  display: flex;
  justify-content: center;
  font-family: Poly;
  font-size: 6vw; 
  font-weight: 400;
  line-height: 7vw;
  color: #FF0000;
  margin-bottom: 5vw;
}

.abt-title2 {
  display: flex;
  justify-content: center;
  font-family: Poly;
  font-size: 5vw;
  font-weight: 400;
  line-height: 6vw;
  text-align: center;
  color: #00008B;
  margin-bottom: 5vw;
}

.abt-school-img {
  display: flex;
  justify-content: center;
  border-radius: 10px 0px 0px 0px;
  margin-bottom: 5vw;
}

.abt-rectangles {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.rectangle1, .rectangle2 {
  display: flex;
  flex-direction: column;
  /* width: 90vw; */
  padding: 5vw; 
  margin-bottom: 5vw;
}

.rectangle1 {
  border-radius: 0px 30px 0px 30px;
  background: #00008BC2;
}

.rectangle2 {
  border-radius: 30px 0px 30px 0px;
  background: #FF0000C2;
}

.head1, .head2 {
  font-family: Poly;
  font-size: 8vw; 
  font-weight: 400;
  line-height: 10vw; 
  text-align: center;
  margin-bottom: 2vw;
}

.head1 {
  color: #FF0000;
}

.head2 {
  color: #00008B;
}

.section1, .section2 {
  font-family: Poly;
  font-size: 5vw; 
  font-weight: 400;
  line-height: 6vw;
  text-align: center;
  opacity: 1;
}



.section1 {
  color: white;
}

.section2 {
  color: black;
}

@media (min-width: 768px) {
  .about-container {
    margin-left: 50px;
    margin-right: 50px;
  }
  .abt-title1 {
    font-size: 32px;
    line-height: 38.02px;
    margin-bottom: 2%;
  }
  .abt-title2 {
    font-size: 28px;
    line-height: 33.26px;
    margin-bottom: 2%;
  }
  .abt-school-img {
    margin-bottom: 3%;
  }
  .abt-rectangles {
    width: auto;
  }

  .rectangle1, .rectangle2 {
    width: 1000px;
    padding: 20px;
    margin-left: 2%;
    margin-bottom: 3%;
  }

  .head1, .head2 {
    font-size: 40px;
    line-height: 47.52px;
    margin-bottom: 1%;
  }

  .section1, .section2 {
    font-size: 28px;
    line-height: 33.26px;
  }
}
@media (min-width: 480px) {
    .about-container{
      margin-left: 20px;
      margin-right: 20px;
    }
}