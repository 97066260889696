:root {
  --primary-color: #ff0000;
  --secondary-color: #00008b;
  --text-color: #ffffff;
  --spacing: 10px;
}

.home-container {
  display: flex;
  flex-direction: column;
  margin: var(--spacing) 50px;
}

.home-title1,
.home-title2,
.home-title3 {
  font-family: "Port Lligat Sans", sans-serif; 
  font-size: 32px;
  font-weight: 400;
  text-align: center;
  /* margin-bottom: 2%; */
  
}
.box1{
  display: flex;
  align-items: center;
  float: left;
  margin-top: 2%;
  gap: 2%;
  margin-bottom: 5%;
  margin-top: 8%;
}

.home-title1 {
  color: var(--primary-color);
}

.home-title2 {
  color: var(--secondary-color);
  
}
.underline-component{
  text-decoration: underline;
}
.home-title3{
  color: var(--secondary-color);
  font-family: Poly;
  font-size: 32px;
  font-weight: 400;

}
 
.home-school-img {
  display: flex;
  justify-content: center;
  margin-bottom: 10%;
}
.branches {
  display: grid;
  gap: 20px;
}

.image-container {
  width: 70%;
  margin: 0 auto;
  position: relative;
  overflow: hidden;
  border-radius: 15px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
}

.image-container img {
  width: 100%;
  height: auto;
  transition: transform 0.3s, filter 0.3s;
}

.image-container:hover img {
  transform: scale(1.1);
  filter: brightness(0.9);
}

.overlay-text {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.6);
  color: var(--text-color);
  font-family: "Inter", sans-serif;
  font-size: 20px;
  font-weight: 600;
  text-align: center;
  opacity: 0;
  transition: opacity 0.3s ease;
}
.overlay-text::before,
.overlay-text::after {
  content: '';
  position: absolute;
  border: 5px solid white;
  transition: opacity 0.3s ease, transform 0.3s ease;
  transform: scale(0);
  width: 240px;
  height: 50px;
}

.overlay-text::before {
  top: 10px;
  right: 10px;
  border-bottom: none;
  border-left: none;
}

.overlay-text::after {
  bottom: 10px;
  left: 10px;
  border-top: none;
  border-right: none;
 
}

.website-button {
  margin-top: 4%;
  padding: 10px;
  color: #00008B;
  font-family: Inter;
  background-color: white;
}
.image-container:hover .overlay-text {
  opacity: 1;
}
.image-container:hover .overlay-text::before,
.image-container:hover .overlay-text::after {
  opacity: 1;
  transform: scale(1);
}



.box{
  display: flex;
  align-items: center;
  margin-bottom: 3%;
}
.rec1 {
  display: flex;
  flex-direction: column;
  width: 19px;
  height: 104px;
  border-radius: 0px 10px 10px 0px;
  background: #FF0000;
}
.text-container1{
  display: flex;
  gap: 10px;
  margin-top: 5%;
  margin-bottom: 5%;
}
.img {
  /* width: 442px;
  height: auto;
  transition: transform 0.3s ease; */
  display: block;
  margin: 0 auto;
  width: 100%;
  max-width: 100%;
  height: auto;
}

.img:hover {
  transform: scale(1.1);
  will-change: transform;
}



.mission-vision-div {
  display: flex;
  gap: 2%;
  margin-bottom: 4%;
  
}

.mission,
.vision {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  border-radius: 10px;
  padding: var(--spacing);
  color: var(--text-color);
  
}

.mission {
  background: var(--secondary-color);
  border-radius: 60px 0px 60px 0px;
}

.vision {
  background: var(--primary-color);
  border-radius: 0px 60px 0px 60px;
}

.img2 {
  display: block;
  margin: 0 auto;
  width: 100%;
  max-width: 60%;
  height: auto;
}

.par1,
.par3 {
  font-family: "Poly", serif;
  font-size: 18px;
  line-height: 1.6;
  text-align: left;
}

.sec1-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 2px solid var(--primary-color);
  padding: var(--spacing);
  margin-bottom: 10%;
}

.img2 img {
  width: 100%;
  height: auto;
  border-radius: 10px;
}

.description {
  width: 80%;
  text-align: justify;
}

.read-more-button {
  border: 1px solid var(--primary-color);
  border-radius: 10px;
  font-family: "Poly", serif;
  font-size: 18px;
  color: var(--primary-color);
  padding: 10px 20px;
  background: none;
  cursor: pointer;
  position: relative;;
}

.read-more-button:hover {
  background-color: var(--primary-color);
  color: var(--text-color);
}

.hidden-content {
  display: none;
  transition: opacity 0.3s ease-in-out;
  font-family: Poly;
  font-size: 28px;
  font-weight: 400;
  /* text-align: center; */
  color: #00008B;
}

.hidden-content.show {
  display: block;
  opacity: 1;
}

@media (max-width: 768px) {
  .home-container {
    margin: var(--spacing);
  }

  .mission-vision-div {
    flex-direction: column;
  }

  .text-container1{
    flex-direction: column;
    margin-bottom: 30%;
  }

  .read-more-button {
    width: 100%;
    font-size: 16px;
  }
}
@media (max-width: 480px) {
  .rec1 {
    width: 14px;
    height: 50px;
  }
  .hidden-content{
    font-size: 20px;
    font-weight: 300;
  }

  .sec1-container{
    margin-bottom: 20%;
  }

.home-title1,
.home-title2,
.home-title3 {
  font-size: 20px;
  font-weight: 200;
}
  .home-container {
    margin: var(--spacing);
  }

  .mission-vision-div {
    flex-direction: column;
    margin: var(--spacing) 20px;

  }
  .mission{
    margin-bottom: 5%;
  }

  .text-container1{
    flex-direction: column;
    margin-bottom: 30%;
  }

  .read-more-button {
    width: 100%;
    font-size: 16px;
    
  }
  .rec1 {
    border-radius: 0px 6px 6px 0px;
    background: #FF0000;
  }
  .img2 {
    max-width: 100%;
  }
  .image-container {
    width: 100%;
  }
  .branches {
    gap: 60px;
  }
  
}
