.news-container{
    display: flex;
    flex-direction: column;
    margin-left: 50px;
    margin-right: 50px;
  }

  .news-sec1 {
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 5px 0px 0px 0px;
    border: 2px 0px 0px 0px;
    background: #FFFFFF;
    border: 2px solid #FF0000;
    margin-bottom: 4%;
  }
  
  .news-sec1-title {
    width: 276px;
    height: 48px;
    font-family: Inter;
    font-size: 40px;
    font-weight: 700;
    line-height: 48.41px;
    text-align: center;
    color: #FF0000
  }
  
  .news-sec1-para {
    font-family: Poly;
    font-size: 28px;
    font-weight: 400;
    line-height: 33.26px;
    color: #00008B;
  }

  /* Medium Screens (under 768px) */
@media (max-width: 768px) {
  
  .news-container {
      margin-left: 20px;
      margin-right: 20px;
  }

  .news-sec1 {
      padding: 10px;
      margin-bottom: 5%;
  }

  .news-sec1-title {
      font-size: 32px;
      width: auto; /* Allows width to adjust based on screen */
  }

  .news-sec1-para {
      font-size: 24px;
      line-height: 30px;
  }
}

@media (max-width: 480px) {

  .news-sec1 img{
    width: 99%;
  }
  .news-container {
      margin-left: 10px;
      margin-right: 10px;
  }

  .news-sec1 {
      padding: 8px;
      margin-bottom: 6%;
  }

  .news-sec1-title {
      font-size: 24px;
      line-height: 30px;
  }

  .news-sec1-para {
      font-size: 18px;
      line-height: 24px;
      text-align: center; 
  }
}