.faculties-container-main {
    padding: 20px;
    padding: 50px;
  }
  
  .faculties-container-card {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    gap: 20px;
  }
  
  .faculty-card {
    width: 300px;
    height: 300px;
    border: none;
    margin: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10px;
    text-align: center;
    background-color: transparent;
    border-radius: 10px;
    transition: transform 0.3s ease;
  }
  
  .faculty-card:hover {
    transform: scale(1.05);
  }
  
  .faculty-image {
    width: 200px;
    height: 200px;
    border-radius: 50%;
    border: 2px solid black;
    margin-bottom: 10px;
    transition: transform 0.3s ease;
  }
  
  .faculty-card:hover .faculty-image {
    transform: scale(1.1);
  }
  
  .faculty-info {
    margin-top: 0px;
  }
  
  .faculty-name {
    color: blue;
    margin: 0;
    font-size: medium;
  }
  
  .faculty-qualification {
    color: red;
    margin: 0;
  }
  
  .faculty-experience {
    color: blue;
    margin: 0;
  }
  