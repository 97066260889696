/* Success Container */
.success-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-left: 30px;
    margin-right: 30px;
    height: 100vh;
    background-color: #f9fdfb; /* Light green tint */
  }
  
  /* Success Message */
  .success-message {
    text-align: center;
    background: #ffffff;
    padding: 40px 20px;
    border-radius: 10px;
    box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.1);
    animation: fadeIn 0.8s ease-in-out;
  }
  
  /* Success Icon */
  .success-icon {
    font-size: 50px;
    color: #4caf50; /* Green for success */
    margin-bottom: 20px;
    animation: bounce 1s infinite;
  }
  
  /* Success Title */
  .success-title {
    font-size: 28px;
    font-weight: bold;
    color: #333333;
    margin-bottom: 10%;
  }
  
  /* Success Text */
  .success-text {
    font-size: 16px;
    color: #666666;
  }
  
  /* Animations */
  @keyframes fadeIn {
    from {
      opacity: 0;
      transform: scale(0.8);
    }
    to {
      opacity: 1;
      transform: scale(1);
    }
  }
  
  @keyframes bounce {
    0%, 100% {
      transform: translateY(0);
    }
    50% {
      transform: translateY(-10px);
    }
  }
  