.b1-header{
    display: flex;
    flex-direction: column;
    /* margin-top: 2%; */
    padding: 10px;
    background-color: #ffffff;
}
.b1-logo-title{
    display: flex;
    justify-content: left;
    gap: 2%;
}
.b1-school-name {
    font-family: 'Port Lligat Sans', sans-serif;
    font-size: 48px;
    font-weight: 400;
    color: #00008B;
    padding-top:3%;
    box-shadow: 0 1px rgba(0, 0, 0, 0.3); 
}

.b1-nav-buttons {
    display: flex;
    align-items: center;
    gap: 20px; 
    justify-content: center;
    background-color: #00008b; 
    border-radius: 5px;
    padding: 20px;
    margin-bottom: 2%;
}

.b1-button {
    background-color: transparent; 
    color: white;
    border: none;
    /* padding: 10px 20px; */
    font-size: 16px;
    cursor: pointer;
    position: relative;
    transition: color 0.3s ease;
}
.b1-button::after {
    
    height: 2px;
    width: 0;
    background-color: red; 
    position: absolute;
    bottom: 0;
    left: 0;
    transition: width 0.3s ease; 
} 

.b1-button:hover::after {
    width: 100%; 
}

.b1-button:hover {
    color: #ff6347; 
}

 /* Sidebar Styles */
 .b1-nav-buttons.open {
    display: none;
    position: fixed;
    left: -250px;
    top: 0;
    height: 100%;
    background-color: #00008b;
    flex-direction: column;
    gap: 20px;
    padding: 20px;
    transition: left 0.3s ease;
    z-index: 100;
  }
  .b1-nav-buttons.open {
    left: 0;
  }



@media (max-width: 768px) {
    .b1-school-name {
        font-size: 32px;
    }
    .b1-nav-buttons{
        display: none;
    }

    .b1-nav-buttons {
        flex-direction: column;
        gap: 10px;
    }

    .b1-button {
        width: 100%;
        text-align: center;
    }
    .b1-nav-buttons.open{
        display: flex;
    }
}
@media (max-width: 480px) {
    .b1-school-name {
        font-size: 24px;
      }
    
}
