.admissionCont {
    background-color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
  }
  
  .heading {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-family: 'Poly', sans-serif;
    font-size: 32px;
    font-weight: 400;
    line-height: 1.2;
    text-align: center;
    padding: 20px;
  }
  
  .buttonCont {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 15px;
    margin: 20px 0;
  }
  
  .admission-buttons {
    width: 90%;
    max-width: 295px;
    height: 55px;
    border-radius: 10px;
    background: #ff0000;
    color: #00008b;
    font-size: 20px;
    font-weight: 400;
    text-align: center;
    cursor: pointer;
    border: none;
  }
  
  hr {
    width: 90%;
    height: 2px;
    background-color: #ff0000;
    border: none;
  }
  
  .importantPointsContainer {
    display: flex;
    justify-content: center;
    padding: 10px 0;
  }
  
  .pointsContainer {
    width: 90%;
    font-family: 'Poly', sans-serif;
    font-size: 18px;
    line-height: 1.5;
    text-align: left;
  }
  
  ol {
    padding-left: 20px;
  }
  
  @media (max-width: 768px) { 
    .heading {
      font-size: 24px;
    }
  
   .admission-buttons {
      font-size: 18px;
      height: 50px; 
    }
  
    .pointsContainer {
      font-size: 16px;
    }
  }
  